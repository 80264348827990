<template>
  <div>
    <div
      v-if="displayTiles"
      style="padding: 0 0 60px 0;"
    >
      <div class="supportHeaderContainer">
        <div class="supportHeader">
          <h3>
            Only support functions that you have permissions for are visible.
          </h3>
        </div>
      </div>
      <template>        
        <tileComponent
          id="release-notes"
          :icon-colour="this.$store.getters.loginButtonColour"
          :icon-colour-hovered="this.$store.getters.loginButtonColourSelected"
          title="Release Notes"
          description="View the application version release notes"
          icon="mdi-note-multiple"
          @setCurrentView="setCurrentView('release-notes')"
        />
        <tileComponent
          id="system-status"
          :icon-colour="this.$store.getters.loginButtonColour"
          :icon-colour-hovered="this.$store.getters.loginButtonColourSelected"
          title="System Status"
          description="Shows the system status and system and database versions"
          icon="mdi-help-network"
          @setCurrentView="setCurrentView('system-status')"
        />
      </template>
    </div>
    <component
      v-else
      :is="currentView"
      @setTileMode="setTileMode"
    />
  </div>
</template>

<script>

import tileComponent from '../components/support/tileComponent'
import CreateCustomer from '../components/support/createCustomer'
import UpdateCompany from '../components/support/updateCompany'
import EmailSuffix from '../components/support/emailSuffix/emailSuffixComponent'
import requestingUsers from '../components/support/requestingUsers/requestingUsers'
import userComponent from '../components/support/assignCompanies/userComponent'
import releaseNotes from '../components/support/releaseNotes'
import systemStatus from '../components/support/systemStatus'
import contextManagement from '../components/support/contextManagement/contextComponent'
import { DataEventBus } from '../events/dataEvents'

export default {
  name: 'Support',
  metaInfo: {
    title: 'icon portal - Support Page'
  },
  components: {
    tileComponent,
    CreateCustomer,
    UpdateCompany,
    EmailSuffix,
    requestingUsers,
    userComponent,
    releaseNotes,
    systemStatus,
    contextManagement
  },
  mixins: [
  ],
  data () {
    return {
      displayTiles: true,
      currentView: null,
      currentCompanyId: 0
    }
  },
  computed: {
    getColourStyles () {
      return '#93C73E'
    },
    canTest () {
      return (this.currentCompanyId === this.$store.getters.channelCompany.companyId) &&
        this.$store.getters.maintelTesting
    },
    canCreateCustomer () {
      return (this.currentCompanyId === this.$store.getters.channelCompany.companyId) &&
        this.$store.getters.createCustomerPermission
    },
    canUpdateCompany () {
      return (this.currentCompanyId === this.$store.getters.channelCompany.companyId) &&
        this.$store.getters.editCompanyPermission
    },
    canCreateEntity () {
      return (this.currentCompanyId === this.$store.getters.channelCompany.companyId) &&
        this.$store.getters.createEntityPermission
    }
  },
  mounted () {
    this.initDisplayFlags()
    this.currentCompanyId = this.$store.getters.currentCompany.id
    DataEventBus.$on('company-changed', (company) => {
      this.currentCompanyId = company.id
    })
    DataEventBus.$emit('still-active')
  },
  methods: {
    initDisplayFlags () {
      this.displayTiles = true
      this.currentView = null
    },
    setCurrentView (id) {
      DataEventBus.$emit('still-active')
      this.displayTiles = false
      switch (id) {
        case 'create-customer':
          this.currentView = 'CreateCustomer'
          break
        case 'update-company':
          this.currentView = 'UpdateCompany'
          break
        case 'email-suffixes':
          this.currentView = 'EmailSuffix'
          break
        case 'upload-dashboard-image':
          this.currentView = 'UploadDashboardImage'
          break
        case 'requesting-users':
          this.currentView = 'requestingUsers'
          break
        case 'release-notes':
          this.currentView = 'releaseNotes'
          break
        case 'assign-company':
          this.currentView = 'userComponent'
          break
        case 'system-status':
          this.currentView = 'systemStatus'
          break
        case 'context-management':
          this.currentView = 'contextManagement'
          break
        default:
          this.currentView = null
          break
      }
      DataEventBus.$emit('title-changed', `iQuote`)
    },
    setTileMode () {
      this.displayTiles = true
      DataEventBus.$emit('title-changed', 'iQuote')
    }
  }
}
</script>
<style scoped>
.supportHeaderContainer {
  margin: 20px;
}
.supportHeader {
  font-size: 1.2em;
}
</style>
